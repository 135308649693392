@import './variables.scss';

body{
  font-family: 'Inter', sans-serif;
  margin: 0;
  background-color: $background-color;
  font-size: 1vw;
  line-height: 1;
  @media screen and (max-width: 991px){
    font-size: 1.5vw;
  }
}

html{
  -webkit-text-size-adjust: 100%;
}

p{
  margin: 0;
}