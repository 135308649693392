$black: #000000;
$black-80: #040D1C;
$black-70: #0F1729;
$black-60: #2E3950;
$white: #FFFFFF;
$white-80: #E3ECEF;
$white-blue: #B6C3DC;

$grey: #969696;
$grey-90: #767F92;
$grey-80: #efefef;
$grey-light: #e5e5e5;

$yello: #d5ff2e;
$yellow-dark: #98bf00;

$purple: #7b07f7;
$purple-dark: #0d1426;

$green: #25CE8F;

$blue: #2187D0;
// for home page

$background-color: var(--background-color, $grey-80);
$background-secondary-color: var(--background-secondary-color, $grey-light); 
$background-ternay-color: var(--background-color, $white);
$background-highlighted-color: var(--background-highlighted-color, $black);
$background-purple-color: var(--background-purple-color, $purple);

$text-color: var(--text-color, $black);
$text-secondary-color: var(--text-secondary-color, $grey);
$text-ternary-color: var(--text-ternary-color, $white);
$text-color-4: var(--text-ternary-color, $white-80);
$text-5: var(--text-5, $white-blue);
$text-6: var(--text-6, $blue);
$text-7: var(--text-7, $green);
$text-8: var(--text-8, $grey-90);
$text-9: var(--text-9, $black-60);

$btn-background-color: var(--btn-background-color, $yello);
$btn-background-hover-color: var(--btn-background-hover-color, $yellow-dark);

$btn-background-black-color: var(--btn-background-black-color, $black);
$btn-background-white-color: var(--btn-background-white-color, $white);

$btn-background-black-70: var(--btn-background-black-70, $black-70);;

// for flash loan page, updated home page
$flash-loan-background-color: var(--flash-loan-background-color, $purple-dark);

$flash-button-background: var(--flash-button-background, $green);

$footer-background-color: var(--footer-background-color, $black-80);